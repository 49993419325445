<template>
  <div class="container">
    <div class="row content-cart">
      <table class="table cart-desktop">
        <thead>
          <tr>
            <th scope="col"></th>
            <th scope="col" style="text-align: left">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商品資料</th>
            <th scope="col">價格</th>
            <th scope="col">數量</th>
            <th scope="col">備註</th>
            <th scope="col">小計</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="`item${index}`" v-for="(item, index) in currentCart">
            <td>
              <a href="javascript:" style="color:black;" @click.prevent="remove(item)">X</a>
            </td>
            <td>
              <div class="row">
                <img :src="item[1].image" :alt="item[1].title" style="width:80px;">
                <p>{{item[1].title}}</p>
              </div>
            </td>
            <td>
              ${{item[1].amount}}
              <!-- 國際訂單可修改價格 -->
              <input v-if="couponType === 'trade'" v-model="customAmount[item[0]]" @change.prevent="updateAmount" :data-item-key="item[0]" min="0" type="number" class="trade-item-input" placeholder="自訂價格...">
            </td>
            <td>
              <div class="btn-group">
                <button @click.prevent="minus(item)" type="button" class="prev btn">-</button>
                <input @change.prevent="changeQty($event, item)" type="number" style="width:50px;text-align:center;border:0;border-top:1px solid #999;border-bottom:1px solid #999;" :value="item[1].qty">
                <!-- <button type="button" class="show-number btn ">{{item[1].qty}}</button> -->
                <button @click.prevent="plus(item)" type="button" class="next btn">+</button>
              </div>
            </td>
            <!-- 備註 -->
            <td>
              <input type="text" v-model="itemComments[item[0]]" class="form-control" style="width:120px" placeholder="備註...">
            </td>
            <td><span>${{(item[1].qty * parseFloat(customAmount[item[0]])).toFixed(2)}}</span></td>
          </tr>
          <!-- total-->
          <tr>
            <td class="low" colspan="5">
              <p class="float-right">商品金額:</p>
            </td>
            <td class="low"><span>${{itemsAmount}}</span></td>
          </tr>
          <tr>
            <td class="low">
              <p class="float-right">代金劵{{currentCoupons.length}}張:</p>
            </td>
            <td class="low" colspan="3">
              <ul class="list-unstyled coupons-list">
                <li :key="`coupon${index}`" v-for="(coupon, index) in currentCoupons" style="padding-left:10px;">
                  <label>
                    <input type="checkbox" :value="coupon.id" v-model="useCoupons[coupon.key]" required>
                    {{coupon.title}} | 滿額:{{coupon.qualify_amount}} | {{coupon.use_time === "one" ? '單次' : '多次'}} | 可折抵: ${{coupon.discount_amount_left}}
                    <span v-if="getThisCouponUsed(coupon) > 0"> | 使用: ${{getThisCouponUsed(coupon)}}</span>
                  </label>
                </li>
              </ul>
            </td>
            <td class="low">
              <p class="float-right">折抵金額:</p>
            </td>
            <td class="low">
              <span>${{couponDiscounts}}</span>
            </td>
          </tr>
          <!-- 應付金額 -->
          <tr>
            <td class="low" colspan="5">
              <p v-if="!hasVat" class="float-right">應付金額:</p>
              <p v-if="hasVat" class="float-right">應付金額(含稅):</p>
            </td>
            <td class="low">
              <span v-if="!hasVat">${{Math.round(itemsAmount - couponDiscounts)}}</span>
              <span v-if="hasVat">${{itemsAmountWithTax - couponDiscounts}}</span>
            </td>
          </tr>
          <!-- 如果couponType為trade或purchasing，則需檢查 currentCustomer 的運費-->
          <tr v-if="(couponType === 'trade' || couponType === 'purchasing') && (itemsAmountWithTax - couponDiscounts < currentCustomer.freight) ">
            <td class="low" colspan="5">
              <p class="float-right">
                <label>
                  <input type="checkbox" v-model="iKnowFreightRequirement"> 我了解本客戶 {{currentCustomer.customer_name}} 訂單未達最低免運金額 <strong>${{currentCustomer.freight}}</strong>
                </label>
              </p>
            </td>
          </tr>
          <!-- 備註 comment -->
          <tr>
            <td>訂單備註: </td>
            <td colspan="5">
              <input v-model="comment" type="text" class="comment-input">
            </td>
          </tr>
          <!-- 收件人資訊 百貨業務才有 -->
          <tr v-if="couponType === 'purchasing' || couponType === 'trade'">
            <td>收件人資訊: </td>
            <td colspan="5">
              <div class="flex">
                <div class="px-05r dib fx1">
                  <input v-model="name" type="text" class="form-control" placeholder="收件人名稱...">
                </div>
                <div class="px-05r dib fx1">
                  <input v-model="phone" type="text" class="form-control" placeholder="收件人電話...">
                </div>
                <div class="px-05r dib fx2">
                  <input v-model="address" type="text" class="form-control" placeholder="收件地址...">
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div :key="`item${index}`" v-for="(item, index) in currentCart" class="cart-mobile">
        <table class="table">
          <tr>
            <td colspan="2">
              <img :src="item[1].image" :alt="item[1].title" style="width:80px;">
            </td>
          </tr>
          <tr>
            <th>商品資料</th>
            <td>{{item[1].title}}</td>
          </tr>
          <tr>
            <th>價格</th>
            <td>
              ${{item[1].amount}}
              <input v-if="couponType === 'trade'" v-model="customAmount[item[0]]" @change.prevent="updateAmount" :data-item-key="item[0]" min="0" type="number" class="trade-item-input" placeholder="自訂價格...">
            </td>
          </tr>
          <tr>
            <th>數量</th>
            <td>
              <div class="btn-group">
                <button @click.prevent="minus(item)" type="button" class="prev btn">-</button>
                <input @change.prevent="changeQty($event, item)" type="number" style="width:50px;text-align:center;border:0;border-top:1px solid #999;border-bottom:1px solid #999;" :value="item[1].qty">
                <!-- <button type="button" class="show-number btn ">{{item[1].qty}}</button> -->
                <button @click.prevent="plus(item)" type="button" class="next btn">+</button>
              </div>
            </td>
          </tr>
          <tr>
            <th>備註</th>
            <td>
              <input type="text" v-model="itemComments[item[0]]" class="trade-item-input" style="width:180px" placeholder="商品備註...">
            </td>
          </tr>
          <tr>
            <th>小計</th>
            <td>${{item[1].qty * parseInt(customAmount[item[0]])}}</td>
          </tr>
        </table>
        <span><a href="javascript:" style="color:black;" @click.prevent="remove(item)">X</a></span>
      </div>
      <!-- 手機版結賬 -->
      <div class="cart-mobile custom-cart-mobile table-responsive">
        <table class="table">
          <tr>
            <th>商品金額: </th>
            <td>${{itemsAmount}}</td>
          </tr>
          <tr>
            <td colspan="2">代金劵 {{currentCoupons.length}}張:</td>
          </tr>
          <tr v-if="currentCoupons.length > 0">
            <td class="mobile-coupons-list" colspan="2">
              <ul class="list-unstyled">
                <li :key="`coupon${index}`" v-for="(coupon, index) in currentCoupons" style="padding-left:10px;text-align:left;">
                  <label>
                    <input type="checkbox" :value="coupon.id" v-model="useCoupons[coupon.key]">
                    {{coupon.title}} <br> 滿額:{{coupon.qualify_amount}} | {{coupon.use_time === "one" ? '單次' : '多次'}} | 可抵: ${{coupon.discount_amount_left}}
                    <span v-if="getThisCouponUsed(coupon) > 0">使用: ${{getThisCouponUsed(coupon)}}</span>
                  </label>
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <th >折抵金額: </th>
            <td>${{couponDiscounts}}</td>
          </tr>
          <tr>
            <th v-if="!hasVat">應付金額: </th>
            <th v-if="hasVat">應付金額(含稅): </th>
            <td v-if="!hasVat">${{itemsAmount - couponDiscounts}}</td>
            <td v-if="hasVat">${{itemsAmountWithTax - couponDiscounts}}</td>
          </tr>
          <!-- 收件人資訊 百貨業務才有 -->
          <tr v-if="couponType === 'purchasing' || couponType === 'trade'">
            <th>收件人</th>
            <td>
              <input v-model="name" type="text" class="trade-item-input" style="width:180px" placeholder="收件人名稱...">
            </td>
          </tr>
          <tr v-if="couponType === 'purchasing' || couponType === 'trade'">
            <th>收件電話</th>
            <td>
              <input v-model="phone" type="text" class="trade-item-input" style="width:180px" placeholder="收件人電話...">
            </td>
          </tr>
          <tr v-if="couponType === 'purchasing' || couponType === 'trade'">
            <th>收件地址</th>
            <td>
              <input v-model="address" type="text" class="trade-item-input" style="width:180px" placeholder="收件地址...">
            </td>
          </tr>
          <!-- 備註 -->
          <tr>
            <th>訂單備註:</th>
            <td>
              <textarea v-model="comment" class="comment-input" style="padding:5px;"></textarea>
            </td>
          </tr>
          <!-- 如果couponType為trade或purchasing，則需檢查 currentCustomer 的運費-->
          <tr v-if="(couponType === 'trade' || couponType === 'purchasing') && (itemsAmountWithTax - couponDiscounts < currentCustomer.freight) ">
            <td colspan="2">
              <label>
                <input type="checkbox" v-model="iKnowFreightRequirement"> 我了解本客戶 {{currentCustomer.customer_name}} 訂單未達最低免運金額 <strong>${{currentCustomer.freight}}</strong>
              </label>
            </td>
          </tr>
        </table>
      </div>
      <!-- 訂單按鈕 -->
      <div class="col-12 text-center">
        <button type="button" class="save-btn" @click.prevent="pending">暫&nbsp;存</button>
        <button type="button" class="submit-btn" @click.prevent="checkout">結&nbsp;帳</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      customAmount: {},
      hasAssignedCustomAmount: false,
      useCoupons: {},
      comment: "",
      iKnowFreightRequirement: false,
      itemComments: {},
      name: "",
      phone: "",
      address: "",
      commentHasUpdated: false,
      updatedRestoreOrder: false
    }
  },
  computed: {
    restoreOrder() {
      return this.$store.state.restoreOrder;
    },
    currentCart() {
      // object轉成array
      return Object.entries(this.$store.state.currentCart);
    },
    currentCoupons() {
      return this.$store.state.currentCoupons;
    },
    couponType() {
      return this.$store.state.couponType;
    },
    couponDiscounts() {
      const selectedCoupons = Object.entries(this.useCoupons).filter(coupon => coupon[1]).map(coupon => coupon[0]);
      let selectedCouponDiscounts = selectedCoupons.reduce((sum, selectedCoupon) => {
        return sum + this.currentCoupons.find(coupon => coupon.key === selectedCoupon).discount_amount_left;
      }, 0);
      if (selectedCouponDiscounts > this.itemsAmount) {
        return selectedCouponDiscounts = this.itemsAmount;
      } else {
        return selectedCouponDiscounts;
      }
    },
    itemsAmount() {
      let result = this.currentCart.reduce(((sum, item) => {
        return sum += item[1].qty * this.customAmount[item[0]];
      }), 0);
      return +result.toFixed(2)
    },
    itemsAmountWithTax() {
      let result = this.currentCart.reduce(((sum, item) => {
        // return sum += Math.round((item[1].qty * this.customAmount[item[0]]) * 1.05);
        // return sum += Math.round(Math.round(item.price * item.qty) * 1.05)
        return sum += Math.round(Math.round(item[1].qty * this.customAmount[item[0]]) * 1.05);
      }), 0);
      return result;
    },
    currentCustomer() {
      return this.$store.state.currentCustomer || {};
    },
    hasVat() {
      // purchasing跟trade的時候會有vat
      return this.couponType === 'purchasing' || this.couponType === 'trade';
    }
  },
  methods: {
    minus(item) {
      if (item[1].qty > 1) {
        axios.patch('/api/change_qty', {item_id: item[0], qty: item[1].qty - 1}).then(({data}) => {
          if (data.success) {
            this.$store.dispatch('getCurrentCart');
            this.$store.dispatch('getCurrentCoupons');
          }
        })
      } else {
        Swal.fire({
          title: '最低數量',
          text: '最少數量為1，如要移除請點擊X',
          icon: 'warning',
          confirmButtonText: '關閉'
        });
      }
      // 重設coupons
      this.useCoupons = {};
    },
    plus(item) {
      if (item[1].qty < item[1].max_qty) {
        axios.patch('/api/change_qty', {item_id: item[0], qty: item[1].qty + 1}).then(({data}) => {
          if (data.success) {
            this.$store.dispatch('getCurrentCart');
            this.$store.dispatch('getCurrentCoupons');
          }
        })
      } else {
        Swal.fire({
          title: '購買上限',
          text: `最多購買量為${item[1].max_qty}!`,
          icon: 'warning',
          confirmButtonText: '關閉'
        })
      }
      // 重設coupons
      this.useCoupons = {};
    },
    changeQty(e, item) {
      if (e.target.value === '' || e.target.value === undefined || e.target.value === null || e.target.value === 'NaN' || parseInt(e.target.value) <= 0) {
        Swal.fire({
          title: '輸入錯誤',
          text: `數量不能為空值或負數！`,
          icon: 'warning',
          confirmButtonText: '關閉'
        }).then(() => {
          window.location.reload();
        });
      } else {
        if (e.target.value < item[1].max_qty) {
          axios.patch('/api/change_qty', {item_id: item[0], qty: parseInt(e.target.value)}).then(({data}) => {
            if (data.success) {
              this.$store.dispatch('getCurrentCart');
              this.$store.dispatch('getCurrentCoupons');
            }
          })
        } else {
          Swal.fire({
            title: '購買上限',
            text: `最多購買量為${item[1].max_qty}!`,
            icon: 'warning',
            confirmButtonText: '關閉'
          })
        }
        // 重設coupons
        this.useCoupons = {};
      }
    },
    remove(item) {
      axios.delete('/api/remove', {data: {item_id: item[0]}}).then(({data}) => {
        if (data.success) {
          this.$store.dispatch('getCurrentCart');
          this.$store.dispatch('getCurrentCoupons');
        }
      });
      // 重設coupons
      this.useCoupons = {};
    },
    updateAmount(e) {
      const itemKey = e.target.getAttribute('data-item-key');
      // this.$set(this.customAmount, itemKey, parseFloat(e.target.value));
      this.customAmount = Object.assign({}, this.customAmount, {...this.customAmount, [itemKey]: parseFloat(e.target.value)});
      this.$forceUpdate();
    },
    getThisCouponUsed(coupon) {
      let sum = 0;
      let returnResult = null;
      Object.keys(this.useCoupons).forEach(key => {
        if (this.useCoupons[key]) { //必須要有這個判定，因為有可能有key但不是true
          if (this.itemsAmount - this.currentCoupons.find(c => c.key === key).discount_amount_left >= 0){
            sum += this.currentCoupons.find(c => c.key === key).discount_amount_left;
            // 如果正好是這個coupon
            if (coupon.key === key) {
              // console.log('case B');
              returnResult = coupon.discount_amount_left;
            }
          } else {
            // 萬一不夠折抵
            let result = this.itemsAmount - sum;
            if (coupon.key === key) {
              // console.log('case C');
              returnResult = result
            }
          }
        }
      });
      return returnResult;
    },
    async pending(e) {
      e.target.disabled = true;
      console.log("暫存...")
      try {
        const use_coupons = Object.entries(this.useCoupons).filter(coupon => coupon[1] === true).map(coupon => coupon[0]);
        axios.post('/api/pending', {use_coupons, custom_amount: this.customAmount, comment: this.comment, item_comments: this.itemComments, name: this.name, phone: this.phone, address: this.address}).then(({data}) => {
          if (data.success) {
            // console.log(data.order);
            Swal.fire({
              title: '儲存成功',
              text: data.message,
              icon: 'success',
              confirmButtonText: '關閉'
            }).then(() => {
              window.location.href = '/users';
            })
          } else {
            Swal.fire({
              title: '儲存失敗',
              text: data.message ? data.message : '發生錯誤，儲存失敗！',
              icon: 'error',
              confirmButtonText: '關閉'
            });
          }
        })
      } catch(error) {
        console.log(error)
      } finally {
        e.target.disabled = false;
      }
    },
    checkout(e) {
      // disable按鈕
      e.target.disabled = true;
      // 如果需要確認運費卻沒有打勾則無法進行
      // 1. 國際貿易與百貨業務, 2. 應付金額小於currentCustomer.freight, 3. 未勾選iKnowFreightRequirement
      if ((this.couponType === 'trade' || this.couponType === 'purchasing') && (this.itemsAmountWithTax - this.couponDiscounts < this.currentCustomer.freight) && !this.iKnowFreightRequirement) {
        Swal.fire({
          title: '請確認',
          text: '請勾選我了解應付金額未達到免運費要求',
          icon: 'warning',
          confirmButtonText: '關閉'
        });
        e.target.disabled = false;
        return;
      }
      // 20220511 增加一規則: 美用品金額如果大於0則不能結帳
      if (this.couponType === 'mei_item' && this.itemsAmount - this.couponDiscounts > 0) {
        Swal.fire({
          title: '請確認',
          text: '美用品的應付金額不能大於0!',
          icon: 'warning',
          confirmButtonText: '關閉'
        });
        e.target.disabled = false;
        return;
      }
      // 產生訂單，只送出customAmount與使用的coupon
      const use_coupons = Object.entries(this.useCoupons).filter(coupon => coupon[1] === true).map(coupon => coupon[0]);
      axios.post('/api/order_create', {use_coupons, custom_amount: this.customAmount, comment: this.comment, item_comments: this.itemComments, name: this.name, phone: this.phone, address: this.address}).then(({data}) => {
        if (data.success) {
          // console.log(data.order);
          Swal.fire({
            title: '訂單成立',
            text: data.message,
            icon: 'success',
            confirmButtonText: '關閉'
          }).then(() => {
            // 避免因清空購物車造成頁面轉回/items
            setTimeout(() => {
              this.$store.dispatch('clearCurrentCart');
            }, 0);
            // 回到users
            window.location.href = '/users';
          })
        } else {
          Swal.fire({
            title: '訂單失敗',
            text: data.message ? data.message : '訂單失敗，請重新輸入',
            icon: 'error',
            confirmButtonText: '關閉'
          });
          e.target.disabled = false;
        }
      }).catch(err => console.log(err))
    }
  },
  watch: {
    currentCart(newVal, oldVal) {
      if (!this.hasAssignedCustomAmount) {
        this.currentCart.forEach(item => {
          this.customAmount[item[0]] = item[1].amount;
        });
        this.hasAssignedCustomAmount = true;
      }
      if (!this.commentHasUpdated) {
        this.currentCart.forEach(item => {
          this.itemComments[item[0]] = item[1].comment;
        });
        this.commentHasUpdated = true;
      }
      if (newVal.length === 0){
        window.location.href = '/items';
      }
    },
    customAmount: {
      deep: true,
      immediate: true,
    },
    restoreOrder(newVal, oldVal) {
      if (!this.updatedRestoreOrder) {
        this.name = newVal.name;
        this.phone = newVal.phone;
        this.address = newVal.address;
        this.comment = newVal.comment;
        this.updatedRestoreOrder = true;
      }
    }
  },
  created() {
    this.$store.dispatch('getCurrentCart');
    this.$store.dispatch('getCurrentCoupons');
    this.$store.dispatch('getRestoreOrder');
  }
}
</script>
