<template>
  <li>
    <a href="#" class="cart-index">
      <img src="/imager/home/bag-2.png" alt="" style="width: 16px;height: 22px;margin-top: -10px;">
      <div class="number-cart">
        {{items.length}}
      </div>
    </a>
    <div class="widget_shopping_cart">
      <div class="widget_shopping_cart_content">
        <ul class="woocommerce-mini-cart cart_list product_list_widget ">
          <li :key="`item${index}`" v-for="(item, index) in items" class="woocommerce-mini-cart-item mini_cart_item clearfix">
            <span class="product-image" href="#">
              <img :src="item[1].image" :alt="item[1].title" style="width:80px;">
            </span>
            <span class="product-title hide-text" href="#">{{item[1].title}}</span>
            <span class="woocommerce-Price-amount amount">
              <span class="woocommerce-Price-currencySymbol">$</span>{{item[1].amount}}
            </span>
            <span class="quantity">數量: {{item[1].qty}}</span>
            <a href="javascript:" class="remove" @click.prevent="remove(item)"><span class="lnr lnr-cross"></span></a>
          </li>
        </ul>
        <p class="woocommerce-mini-cart__total total">
          <span>小計:</span>
          <span class="woocommerce-Price-amount amount">
            <span class="woocommerce-Price-currencySymbol">$</span>{{total}}
          </span>
        </p>
        <p v-if="items.length > 0" class="woocommerce-mini-cart__buttons buttons">
          <a href="/carts" class="button wc-forward au-btn btn-small">查看購物車 & 結賬</a>
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      items: []
    }
  },
  computed: {
    currentCart() {
      return this.$store.state.currentCart
    },
    total: function() {
      if (this.items.length > 0) {
        let result = this.items.reduce((sum, item) => {
          return sum + item[1].amount * item[1].qty
        }, 0);
        return +result.toFixed(2);
      } else {
        return 0;
      }

    }
  },
  watch: {
    currentCart: function() {
      this.items = Object.entries(this.currentCart)
    }
  },
  methods: {
    remove(item) {
      axios.delete('/api/remove', {data: {item_id: item[0]}}).then(({data}) => {
        if (data.success) {
          this.$store.dispatch('getCurrentCart')
        }
      })
    }
  }
}
</script>